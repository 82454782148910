<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="rnosNombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="rules.required.concat([
                  rules.requiredTrim(rnosNombre),
                  rules.maxLength(rnosNombre, 150)
                ])"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="rnosSigla"
                label="Sigla"
                type="text"
                dense
                outlined
                :rules="([
                  rules.requiredTrim(rnosSigla),
                  rules.maxLength(rnosSigla, 10)
                ])"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="rnosCodigo"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Código"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(rnosCodigo, 9)])"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-autocomplete
                v-model="localidadSelected"
                :items="localidades"
                label="Localidad"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="telefono"
                label="Teléfono"
                type="text"
                dense
                outlined
                :rules="[rules.maxLength(telefono, 50)]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="domicilio"
                label="Domicilio"
                dense
                outlined
                :rules="[rules.maxLength(domicilio, 250)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="set"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditRnosObrasSociales",
  props: {
    editObrasSoc: {
      type: Object
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_OBRAS_SOC_RNOS,
    newTitle: enums.titles.NUEVA_OBRA_SOC_RNOS,
    rules: rules,
    rnosNombre: "",
    rnosSigla: "",
    rnosCodigo: null,
    domicilio: "",
    localidadSelected: null,
    localidades: [],
    telefono: ""
  }),
  created() {
    if (this.editObrasSoc) {
      this.setObrasSocRnos();
    } else {
      this.newObraSocRnos();
    }
    this.localidadSelect();
  },

  methods: {
    ...mapActions({
      saveObraSocial: "configAfiliaciones/saveObraSocial",
      getLocalidades: "afiliaciones/getLocalidades",
      setAlert: "user/setAlert"
    }),

    async localidadSelect() {
      const response = await this.getLocalidades();
      this.localidades = response;
    },

    async setObrasSocRnos() {
      this.rnosId = this.editObrasSoc.rnosId;
      this.rnosNombre = this.editObrasSoc.rnosNombre;
      this.domicilio = this.editObrasSoc.domicilio;
      this.localidadSelected = this.editObrasSoc.locId;
      this.rnosCodigo = this.editObrasSoc.rnosCodigo;
      this.rnosSigla = this.editObrasSoc.rnosSigla;
      this.telefono = this.editObrasSoc.telefono;
    },
    async newObraSocRnos() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      const data = {
        rnosId: this.editObrasSoc?.rnosId,
        rnosNombre: this.rnosNombre,
        domicilio: this.domicilio,
        locId: this.localidadSelected,
        rnosCodigo: this.rnosCodigo,
        rnosSigla: this.rnosSigla.trim(),
        telefono: this.telefono
      };

      const res = await this.saveObraSocial(data);
      if (res.status === 200) {
        setTimeout(() => {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }, 1200);
        this.set();
      }
    },

    set() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
