<template>
<v-card>
  <v-container>
     <v-card-title class="pl-1 primary--text"> Regímenes </v-card-title>
    <v-data-table
      :headers="headers"
      :items="regimenesItems"
      class="elevation-1"
      :search="search"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()"> Nuevo </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="16"
              v-on="on"
              @click="openModal(item)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar regímen</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="16"
              v-on="on"
              @click="deleteReg(item.regId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar regímen</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div v-if="openModalEdit">
      <v-dialog
        v-model="openModalEdit"
        max-width="40%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditRegimenesRnos
          :regimenesItems="regimenesItems"
          :editRegimen="editRegimen"
          :rnosId="rnosId"
          @closeAndReload="closeAndReload"
        ></EditRegimenesRnos>
      </v-dialog>
    </div>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="mt-2" color="primary" @click="closeModalReg"> Cerrar </v-btn>
      </v-card-actions>
  </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import EditRegimenesRnos from "@/components/modules/afiliaciones/afiliados/configuracion/EditRegimenesRnos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditRegimenesRnos
  },
  name: "RegimenesRnos",

  props: ["rnosId"],
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    title: enums.titles.RNOS,
    titleDelete: "¿Eliminar Regímen?",
    regimenesItems: [],
    editRegimen: {},
    headers: [
      {
        text: "Nombre",
        value: "regNombre",
        sortable: false,
        
      },

      {
        text: "Código",
        value: "regCodigo",
        sortable: false,
      
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadRegimenes();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getRegimenesByRnos: "configAfiliaciones/getRegimenesByRnos",
      deleteRegimen: "configAfiliaciones/deleteRegimen",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_REGIMEN:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_REGIMEN:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_REGIMEN:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadRegimenes() {
      this.isLoading = true;
      const data = await this.getRegimenesByRnos(this.rnosId);
      this.regimenesItems = data;
      this.isLoading = false;
    },

    deleteReg(id) {
      this.showDeleteModal = true;
      this.itemToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteRegimen({
        regId: this.itemToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadRegimenes();
      }
    },
    openModal(id) {
      this.openModalEdit = true;
      this.editRegimen = id;
    },
    closeModalReg() {
      this.$emit("closeModalRegimen");
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadRegimenes();
    },
  }
};
</script>

<style lang="scss" scoped></style>
