<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="ObrasSocialesRnos"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()"> Nuevo </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="scale-transition" bottom origin="center center">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      size="20"
                      @click="seeDelYReg(item.rnosId)"
                    >
                      {{ movements }}
                    </v-icon>
                  </template>
                  <span>Ver delegaciones y regímenes</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in opciones"
                  :key="item.title"
                  link
                  @click="openModalDelYReg(item.id)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar obra social</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="deleteObraSocialRnos(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar obra social</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalDelegacion"
      v-model="openModalDelegacion"
      max-width="85%"
      @keydown.esc="closeModal"
      persistent
    >
      <DelegacionesRnos
        :rnosId="rnosId"
        @closeModalDelegacion="closeModal"
      ></DelegacionesRnos
    ></v-dialog>

    <v-dialog
      v-if="openModalRegimen"
      v-model="openModalRegimen"
      max-width="85%"
      @keydown.esc="closeModal"
      persistent
    >
      <RegimenesRnos
        :rnosId="rnosId"
        @closeModalRegimen="closeModal"
      ></RegimenesRnos
    ></v-dialog>

    <div v-if="openModalEdit">
      <v-dialog
        v-model="openModalEdit"
        max-width="50%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditRnosObrasSociales
          :ObrasSocialesRnos="ObrasSocialesRnos"
          :editObrasSoc="editObrasSoc"
          @closeAndReload="closeAndReload"
        ></EditRnosObrasSociales>
      </v-dialog>
    </div>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import EditRnosObrasSociales from "@/components/modules/afiliaciones/afiliados/configuracion/EditRnosObrasSociales.vue";
import DelegacionesRnos from "@/components/modules/afiliaciones/afiliados/configuracion/DelegacionesRnos.vue";
import RegimenesRnos from "@/components/modules/afiliaciones/afiliados/configuracion/RegimenesRnos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditRnosObrasSociales,
    DelegacionesRnos,
    RegimenesRnos,
    Ayuda
  },
  name: "RnosObrasSociales",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    movements: enums.icons.MORE_ITEMS,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.RNOS_OBRAS_SOCIALES,
    opciones: [
      { title: "Delegaciones", id: 1 },
      { title: "Regímenes", id: 2 }
    ],
    search: "",
    rules: rules,
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "TablasAfiliados",
    title: enums.titles.RNOS,
    titleDelete: "¿Eliminar obra social?",
    ObrasSocialesRnos: [],
    editObrasSoc: {},
    headers: [
      {
        text: "Nombre",
        value: "rnosNombre",
        sortable: true
      },
      {
        text: "Sigla",
        value: "rnosSigla",
        sortable: false
      },
      {
        text: "Código",
        value: "rnosCodigo",
        sortable: false
      },
      {
        text: "Domicilio",
        value: "domicilio",
        sortable: false
      },
      {
        text: "Localidad",
        value: "locNombre",
        sortable: false
      },
      {
        text: "Teléfono",
        value: "telefono",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
    openModalDelegacion: false,
    openModalRegimen: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadObrasSocialesRnos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getRnos: "configAfiliaciones/getRnos",
      deleteObraSocial: "configAfiliaciones/deleteObraSocial",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_OS_RNOS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_OS_RNOS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_OS_RNOS:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadObrasSocialesRnos() {
      this.isLoading = true;
      const data = await this.getRnos();
      this.ObrasSocialesRnos = data;
      this.isLoading = false;
    },

    deleteObraSocialRnos(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteObraSocial({
        rnosId: this.itemToDelete.rnosId
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadObrasSocialesRnos();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editObrasSoc = item;
    },

    closeAndReload() {
      this.openModalEdit = false;
      this.loadObrasSocialesRnos();
    },

    openModalDelYReg(id) {
      switch (id) {
        case 1:
          this.openModalDelegacion = true;
          this.openModalRegimen = false;
          break;
        case 2:
          this.openModalRegimen = true;
          this.openModalDelegacion = false;
          break;
        default:
          break;
      }
    },
    closeModal() {
      this.openModalDelegacion = false;
      this.openModalRegimen = false;
    },
    seeDelYReg(item) {
      this.rnosId = item;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
