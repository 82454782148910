<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="delNombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="rules.required.concat([
                  rules.requiredTrim(delNombre),
                  rules.maxLength(delNombre, 250)
                ])"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="delNro"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Número"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(delNro, 5)])"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="delCodigo"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Código"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(delCodigo, 6)])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="delLibro"
                label="Libro"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(delLibro, 10)])"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="delTomo"
                label="Tomo"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(delTomo, 10)])"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    hint="Formato DD/MM/AAAA"
                    @blur="delFecha = parseDateToIso(fechaSelected)"
                    v-mask="'##/##/####'"
                    outlined
                    :rules="rules.required"
                    dense
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="delFecha"
                  scrollable
                  label="Fecha"
                  no-title
                  @change="fechaSelected = formatDate(delFecha)"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="set"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditDelegacionesRnos",
  props: {
    editDelegacion: {
      type: Object
    },
    rnosId: {
      type: Number
    }
  },
  directives: { mask },

  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_DELEGACION_RNOS,
    newTitle: enums.titles.NUEVA_DELEGACION_RNOS,
    calendarIcon: enums.icons.CALENDAR,
    rules: rules,
    menu: false,
    delNombre: "",
    delCodigo: null,
    delNro: null,
    fechaSelected: null,
    delLibro: null,
    delTomo: null,
    delFecha: ""
  }),
  created() {
    if (this.editDelegacion) {
      this.setDelegacion();
    } else {
      this.newDelegacion();
    }
  },

  methods: {
    ...mapActions({
      saveDelegacion: "configAfiliaciones/saveDelegacion",
      setAlert: "user/setAlert"
    }),

    async setDelegacion() {
      this.delId = this.editDelegacion.delId;
      this.delNombre = this.editDelegacion.delNombre;
      this.delNro = this.editDelegacion.delNro;
      this.delCodigo = this.editDelegacion.delCodigo;
      this.delLibro = this.editDelegacion.delLibro;
      this.delTomo = this.editDelegacion.delTomo;
      this.delFecha = this.parseDateToIso(this.editDelegacion.delFecha);
  
      this.fechaSelected = this.formatDate(this.delFecha);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async newDelegacion() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
     
      const data = {
        rnosId: this.rnosId,
        delId: this.editDelegacion?.delId,
        delNombre: this.delNombre,
        delNro: this.delNro,
        delCodigo: this.delCodigo,
        delLibro: this.delLibro,
        delTomo: this.delTomo,
        delFecha: this.parseDateToIso(this.fechaSelected),
        
            
      };
    
      const res = await this.saveDelegacion(data);
      if (res.status === 200) {
        setTimeout(() => {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }, 1200);
        this.set();
      }
    },

    set() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
